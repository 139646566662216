import { useEffect, useRef, useState } from 'react'
import { Object3D, AnimationMixer, Clock, AnimationAction,  Color, Box3, Vector3, sRGBEncoding, MeshBasicMaterial, ShaderMaterial } from 'three'
import arService from '../../../services/arService'
import debugService from '../../../services/debugService'
import interactionService from '../../../services/interactionService'
import { interactive } from '../../../services/storyService'
import AnimateObject from '../animateObject'
import PointOfInterest from '../pointOfInterest'
import {ChromakeyMaterial} from './shaders/Chromakey'
import * as THREE from 'three'
import BaseVideo from '../../Common/BaseVideo'
import { ThreeMFLoader } from 'three-stdlib'
import ShadowObject from '../shadowObject'
import videoService from '../../../services/videoService'

interface ChromakeyObject {
  objects: Array<Object3D>
  data: interactive
}

// Video 
const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 1000;

const BUFFERED_AUTOPLAY = true;


let vid
let videoTexture


const ChromakeyObject: Object3D<ChromakeyObject> = ({objects, data}) => {

const [isPlaying, setIsPlaying] = useState(false);
const [isWaiting, setIsWaiting] = useState(false);

const isWaitingTimeout = useRef(null);
const isPlayingTimeout = useRef(null);

  const handleInteractionClick = event => {

    if(videoService.getState().mode !== "READYTOPLAY") return

    if(vid.readyState === 4 || vid.readyState === 3 ) {
      if(isPlaying) return

        playVideo();
      

      }
  }
  


  const playVideo = () => {
    console.log('play video');

    vid.currentTime = 0;
    vid.play();
    vid.muted = false;

    //Set videotexture to shader
    objects[0].material.uniforms.tex = {value: videoTexture}

    //Hide POI

    videoService.setState({mode: "PLAYING"})
    interactionService.setState({mode: "PLAYING"})
  }  

  const pauseVideo = () => {
    console.log('pause video');
    vid.pause();
  }


  const loadStartHandler = () => {
    console.log('loadStartHandler');
    videoService.setState({mode: "BUFFERING"})

  }

  const canplayHandler = () => {
    console.log('canplayHandler');
    console.log(vid.readyState);

    videoService.setState({mode: "READYTOPLAY"})
    //Hide POI

  }

  const progressHandler = () => {
    //console.log('progressHandler');
    //console.log(vid.readyState)
    
    clearTimeout(isWaitingTimeout.current);

    isWaitingTimeout.current = setTimeout(() => {
      setIsWaiting(true);
    }, WAITING_DEBOUNCE_TIME);

  };

  

  const playHandler = () => {
    console.log('playHandler');

    clearTimeout(isWaitingTimeout.current);
    clearTimeout(isPlayingTimeout.current);

    isPlayingTimeout.current = setTimeout(() => {
      setIsPlaying(true);
      setIsWaiting(false);
    }, PLAYING_DEBOUNCE_TIME);
  };

  const pauseHandler = () => {
    console.log('pauseHandler');

    clearTimeout(isWaitingTimeout.current);
    clearTimeout(isPlayingTimeout.current);

    isPlayingTimeout.current = setTimeout(() => {
      setIsPlaying(false);
      setIsWaiting(false);
    }, PLAYING_DEBOUNCE_TIME);
  };


  const endedHandler = () => {

    setIsPlaying(false);
    console.log('Video stopped either because 1) it was over, ' +
          'or 2) no further data is available.');

    vid.removeEventListener('ended', endedHandler);
    window.location.href = window.location.href.replace("ar/","share/");

  }


  const createVideo = () => {
    console.log('createVideo');
      
    //Load Video
    vid = document.createElement("video");
    vid.src = '/res/vid/GreenAR.mp4';
    //vid.crossOrigin = "Anonymous";
    //vid.loop = true;

    vid.autoplay = false;
    vid.muted = true;
    vid.playsInline = true;

    //Subscribe to events
    vid.addEventListener('loadstart', loadStartHandler);
    vid.addEventListener("progress", progressHandler);
    vid.addEventListener("canplaythrough", canplayHandler);

    vid.addEventListener("play", playHandler);
    //vid.addEventListener("playing", playHandler);
    vid.addEventListener("pause", pauseHandler);  
    vid.addEventListener('ended', endedHandler);

    vid.load();


    videoTexture = new THREE.VideoTexture(vid)
    //videoTexture.needsUpdate = true;
     videoTexture.minFilter = THREE.LinearFilter;
     videoTexture.magFilter = THREE.LinearFilter;

  };

  const getGroupByLevel = (level: number) => {
    const object: Object3D = objects.filter(object => level === object.userData.level)[0]

    if(!object) {
      console.error(`UpgradeObject::return(): No object for level = ${level} found!`)
      return <></>
    }

    //Create Video object // REFACTOR
    createVideo();
    object.material = new ChromakeyMaterial({})
   // object.material.needsUpdate = true;
    object.material.reflectivity = 0
    object.material.transparent = true
    //object.material.depthTest = true
    
    //Set inital texture
    object.material.uniforms.tex = {value: new THREE.TextureLoader().load('/res/tex/GreenAR.jpg')}

    console.log(object)
 

    if(object.material.map)
      object.material.map.encoding = sRGBEncoding


    const animateObjects: Array<Object3D> = object.children.filter(child => child.userData.type === "animate")
    const shadowObjects: Array<Object3D> = object.children.filter(child => child.userData.type === "shadow")

    object.children = []

    const hitboxSize: Vector3 = new Vector3()
    const boundingBox: Box3 = new Box3().setFromObject(object);
    boundingBox.getSize(hitboxSize)
    hitboxSize.setComponent(1, hitboxSize.y * 0.3)

    return (
      <group position={object.position.toArray()} ref={groupRef}>
        <PointOfInterest id={data.id} yOffset={hitboxSize.y * 0.7} text={data.title} />
        <mesh visible={debugService.getState().debugEnabled} onClick={handleInteractionClick}>
          <boxBufferGeometry args={hitboxSize.toArray()}/>
          <meshBasicMaterial 
              color={new Color(0xff00ff)} wireframe={true}
          />
        </mesh>
        <primitive object={object} position={[0.0, 0.0, 0.0]}>
        {/*animateObjects.map(object => <AnimateObject key={object.id} object={object}/>)*/}
        {shadowObjects.map(object => <ShadowObject key={object.id} object={object}/>)}

        </primitive>
      </group>
    )
  }


  const [required] = useState<boolean>(objects[0].userData.required)

  const [level, setLevel] = useState<number>(0)
  const [maxLevel] = useState<number>(objects.length - 1)

  const [group, setGroup] = useState<Object3D | null>(null)
  const [clock] = useState<Clock>(new Clock())
  const [randomOffset] = useState<number>(4.0 * Math.PI * Math.random())
  const groupRef = useRef<Object3D>()

  useEffect(() => {
    requestAnimationFrame(animate)
  }, [])

  const animate = () => {
    requestAnimationFrame(animate)

    if(!groupRef.current) return

  }

  useEffect(() => {
    if(!required) return
    interactionService.getState().registerRequiredInteraction(data.id)
  }, [data.id])

  useEffect(() => {
    setGroup(getGroupByLevel(level))
  }, [level])

  return group ? group : <></>
}

export default ChromakeyObject