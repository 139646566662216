import create from 'zustand'

type CanPlayState = {
  id: number
  ready: boolean
}

type VIDEOSTATE = "BUFFERING" | "READYTOPLAY" | "PLAYING"

type VideoService = {
  mode: VIDEOSTATE
  required: Array<CanPlayState>
  setInteraction: (id: number, interacted?: boolean) => boolean
  reset: () => void
};

const videoService = create<VideoService>((set, get) => {
  return {
    mode: "BUFFERING",
    required: new Array<CanPlayState>(),
    setInteraction: (id, interacted = true) => {
      const entry: CanPlayState = get().required.find(entry => entry.id === id)

      if(!entry) return false

      entry.ready = interacted

      if(interacted)
        set({mode: "BUFFERING"})

      set({required: get().required})  

      return true
    }, 
    reset: () => {
      set({
        mode: "BUFFERING",
        required: new Array<CanPlayState>(),
      })
    }
  }
})

export default videoService