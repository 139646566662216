import { Object3D, MeshBasicMaterial, sRGBEncoding, TextureLoader } from 'three'
import { useEffect } from 'react'

interface ShadowObject {
  object: Object3D
  animations: any
}

const ShadowObject: Object3D<ShadowObject> = ({object}) => {

     //Assign shadow
     object.material = new MeshBasicMaterial()
     object.material.map = new TextureLoader().load(process.env.PUBLIC_URL + "/res/tex/blob-shadow.png")
     object.material.map.encoding = sRGBEncoding

     object.material.transparent = true;
     object.material.opacity = 0.6;
     object.scale.set(1,1,1 );



  useEffect(() => {
    object.traverse(child => {
      if(!child.isMesh) return

      child.frustumCulled = false
    })
  }, [])


  return ShadowObject ? <primitive object={object}/> : <></>
}

export default ShadowObject