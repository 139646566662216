import { FC, useEffect, useState, useRef } from 'react'
import { StyledLogo, ItemContainer, StyledOrientationInfo, StyledText, StyledIcon, MainContainer } from './styles';

import {Headline, HeadlineXl, Text} from "../../styles/typography";

declare const window: any;


interface OrientationInfo {
}

const OrientationInfo: FC<OrientationInfo> = () => {
const [visible, setVisible] = useState<boolean>(window.innerHeight < window.innerWidth)
const [allowRender, setAllowRender] = useState<boolean>(false)

const handleOrientation = () => {
  setVisible(window.innerHeight < window.innerWidth);
  setAllowRender(window.XR8.XrDevice.isDeviceBrowserCompatible());
}

const init = () => {
    window.addEventListener('resize', () => {handleOrientation()})
    window.addEventListener('xrloaded',  
        () => {setAllowRender(window.XR8.XrDevice.isDeviceBrowserCompatible())})   
}

useEffect(() => {
  init()
}, [])


  return (visible && allowRender) ? (
       <StyledOrientationInfo id='orientationInfo'>
          <StyledLogo src={process.env.PUBLIC_URL +  "/res/img/PricewaterhouseCoopers_Logo.png"} />
          <MainContainer>
            <ItemContainer>
              <StyledText>Please change the orientation to portrait to continue using the application.</StyledText>
              <StyledIcon src={process.env.PUBLIC_URL + "/res/img/orientationIcon.svg"} />
            </ItemContainer>
          </MainContainer>
     </StyledOrientationInfo>
     ) :  <></>
}

export default OrientationInfo
