import { ConvexObjectBreaker, DRACOLoader, GLTFLoader } from "three-stdlib"
import { Object3D, Color,  Scene, PerspectiveCamera, AnimationClip, Texture, TextureLoader, sRGBEncoding, MeshBasicMaterial } from 'three'
import create from 'zustand'
import ChromakeyObject from "../../components/arScene/chromakeyObject"
import storyService, { Story } from "../storyService"
import AnimateObject from '../../components/arScene/animateObject'
import * as THREE from 'three'


type ObjectType = 'greenscreen' | 'animate' | 'shadow'


const texturePaths: Array<string> = [
  process.env.PUBLIC_URL + "/res/tex/playIcon.png",
  process.env.PUBLIC_URL + "/res/tex/CheckIcon.png"
]

type SceneService = {
  scene: Scene
  renderer: null
  camera: PerspectiveCamera
  animations: AnimationClip[]
  textures: Map<string, Texture>
  objects: {
    static: Array<Object3D>
    interactive: Array<Object3D>
  }
  load: (story: Story) => void
  reset: () => void
}

const sceneService = create<SceneService>((set, get) => {
  const gltfLoader = new GLTFLoader()
  const dracoLoader = new DRACOLoader()
  const textureLoader = new TextureLoader()

  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.3/"
  )
  gltfLoader.setDRACOLoader(dracoLoader)

  const loadTextures = () => {
    console.log('load textures');
    for(let path of texturePaths) {
      const textureNameParts: Array<string> = path.split('/')
      const textureId: string = textureNameParts[textureNameParts.length - 1].split('.')[0]

      const texture: Texture = textureLoader.load(window.location.origin + path)
      texture.encoding = sRGBEncoding

      if(!get().textures.has(textureId))
        get().textures.set(textureId, texture)
    }
  }

  // parse scene objects into r3f components that can be used in the ArScene component
  const parseScene = (scene, story) => {
    const objects = {
      static: new Array<Object3D>(),
      interactive: new Array<Object3D>()
    }

    const chromakeyObjects: Map<number, Array<Object3D>> = new Map<number, Array<Object3D>>()

    scene.traverse((object) => {
      if(object.type !== "Mesh") return

      const { type } : { type: ObjectType } = object.userData

      // parse interactive objects into custom r3f components based on type set in blender
      switch(type) {
        case 'greenscreen': {
          const { id } = object.userData

          if(!chromakeyObjects.has(id))
          chromakeyObjects.set(id, new Array<Object3D>())
          chromakeyObjects.get(id).push(object)  
          
          object.renderOrder = -1
          break
        }
        case 'shadow': {
          object.renderOrder = -2

          break
        }
        case 'animate': {

          object.material = new MeshBasicMaterial()
          object.material.map = new TextureLoader().load(process.env.PUBLIC_URL + "/res/tex/logo.png")
          object.material.map.encoding = sRGBEncoding
          

          break
        }
        default: {
          console.warn(`sceneService::parse(): Invalid object type!`)
          return;
        }
      }
    })

    chromakeyObjects.forEach((entries: Array<Object3D>, id: number) => {
      objects.interactive.push(<ChromakeyObject key={id} data={story.content.interactives.filter(entry => entry.id === id)[0]} objects={entries}/>)
    })

    // update state of service and trigger rerender based on hooks
    sceneService.setState({objects})
  }

  return {
    scene: null,
    camera: null,
    renderer: null,
    animations: new Array<AnimationClip>(),
    textures: new Map<string, Texture>(),
    objects: {
      static: new Array<Object3D>(),
      interactive: new Array<Object3D>()
    },
    load: (story) => {
      console.log('loading')
      gltfLoader.load(story.assets.modelUri,
        ({scene, animations}) => {
          set({animations})
          parseScene(scene, story)
        },
        () => {},
        (error) => {
          console.error(`sceneService::load(): Failed to load scene from = ${story.assets.modelUri} with error = ${error}!`)
        }
      )
      loadTextures()
    },
    reset: () => {
      set({
        objects: {
          static: new Array<Object3D>(),
          interactive: new Array<Object3D>()
        }
      })

      get().load(storyService.getState().getSelectedStory())
    }
  }
})

export default sceneService