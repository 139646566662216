import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { FC } from 'react';
import { Button } from '../../../components/Common/Button';
import storyService, { Story } from '../../../services/storyService';
import initService from '../../../services/initService';
import {StyledStart, LinksWrapper, StartImage, ImageWrapper} from './styles';
import {Link, Text} from "../../../styles/typography";
import { reset } from '../../../utils/reset';

interface Start {
}

const navLinks = [
    {
        text: "Impressum",
        route: "/impressum"
    },
    {
        text: "Privacy Policy",
        route: "/privacy-policy"
    }
]
const links = [
  {
    text: "Nutzungsbedingungen",
    href: "https://www.pwc.de/de/nutzungsbedingungen.html"
  }
]


const Start: FC<Start> = () => {
  const navigate = useNavigate()
  const { storyUrl } = useParams();

  const forcedStory = "bayer-ar";
  console.log(storyUrl);

  const isInitialized = initService(state => state.initialized)
  if(!isInitialized) return <></>

  const story: Story | null = storyService.getState().selectStory(forcedStory)
  if(!story) navigate('/not-found')



  return (
    <StyledStart headline={story.content.start.title}>

        <ImageWrapper>
            <StartImage {...story.content.start.image} />
        </ImageWrapper>

      <Text>{story.content.start.text}</Text>

      <Button url={`/ar/${story.urlName}`} onClick={reset} text={'View message in AR'} />

      <LinksWrapper>
        {navLinks.map(({route, text}) => <NavLink className={'navlink'} to={`${route}`} >{text}</NavLink>)}
        {/* {links.map(({href, text}) => <Link href={href} target="_blank" rel="noopener">{text}</Link>)} */}
      </LinksWrapper>
    </StyledStart>
  );
}

export default Start;
