import { TouchArea } from './styles'
import { FC, ReactNode } from 'react'
import interactionService from '../../services/interactionService';
import arService from '../../services/arService';

interface TouchContainer {
  children: Array<FC> | FC | ReactNode
}

const TouchContainer: FC<TouchContainer> = ({ children }) => {
  const active = arService(state => state.active)

  const handleTouchStart = event => {
    if (interactionService.getState().mode !== "PLACING") return

    // delay mode switch in order to avoid click event falltrough to platform
    setTimeout(() => interactionService.setState({mode: "PLACED"}), 100)
  };

  return (
    <TouchArea onTouchStart={handleTouchStart} active={active}>
      {children}
    </TouchArea>
  );
}

export default TouchContainer;