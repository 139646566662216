import {FC, useEffect, useState} from "react"
import {StyledArNotification} from "./styles";
import interactionService from "../../services/interactionService";
import {Text} from "../../styles/typography";

interface arNotification {
	text: string
};

const ArNotification: FC<arNotification> = ({ text }) => {
	const mode = interactionService(state => state.mode)
	const [isVisible, setIsVisible] = useState(false)

	useEffect(()=>{
		setTimeout(()=>{
			setIsVisible(true)
		}, 3000)
	}, [])

	return (
		<StyledArNotification isVisible={mode === "PLACED" && isVisible}>
			<Text>{text}</Text>
		</StyledArNotification>
	);
};

export default ArNotification;
